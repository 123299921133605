import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import { BaseParagraph } from '../elements/Paragraphs';
import GoogleMap from '../components/GoogleMap';

const Contact = () => {
  const center = { lat: 43.70736, lng: -79.39719 };
  const mapProps = {
    onMount: map => {
      new window.google.maps.Marker({
        position: center,
        map,
        title: 'Wise Publishing'
      });
    },
    options: {
      center,
      zoom: 14,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#212121'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#212121'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#181818'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#1b1b1b'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#2c2c2c'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#8a8a8a'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#373737'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#3c3c3c'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#4e4e4e'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#3d3d3d'
            }
          ]
        }
      ]
    }
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <Container>
        <Row marginBottom="144">
          <Column>
            <LgTitle>Contact</LgTitle>
          </Column>
          <Column width="5/12">
            <BaseParagraph>
              We are at 40 Eglinton Avenue East, positioned on the northeast
              side of Yonge and Eglinton.
            </BaseParagraph>
            <BaseParagraph>
              To reach reception, please use the lift from the lobby to the 2nd
              floor.
            </BaseParagraph>
            <BaseParagraph>
              40 Eglinton Avenue East, Suite 203 Toronto, Ontario, M4P 3A2
            </BaseParagraph>
            <BaseParagraph>
              <a href="tel:647-352-5595">(647) 352-5595</a>
              <br />
              <a href="mailto:hello@wisepublishing.com">
                hello@wisepublishing.com
              </a>
            </BaseParagraph>
          </Column>
          <Column width="7/12">
            <GoogleMap id="map" {...mapProps} />
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export default Contact;
