import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math } from 'polished';

import { theme } from '../../tailwind.config.js';

export const LgTitle = styled.h1`
  ${tw`font-serif mb-48 text-xl md:text-2xl text-purple-200`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('36/32')};
`;

export const BaseTitle = styled.h2`
  ${tw`font-serif mb-14 text-2xl md:text-3xl text-purple-300`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: ${math('56/44')};
`;

export const SmTitle = styled.h3`
  ${tw`font-bold font-serif mb-48 md:mb-64 text-lg uppercase`};
  ${props => (props.dark ? tw`text-purple-200` : tw`text-grey`)}
  ${props => (props.hideMobile ? tw`hidden` : tw``)};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0 md:mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6 md:mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8 md:mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12 md:mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14 md:mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16 md:mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24 md:mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32 md:mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48 md:mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64 md:mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144 md:mb-144`)}
  line-height: ${math('24/19')};
  
  @media (min-width: ${theme.screens.md}) {
    ${tw`block`};
    ${props => (props.hideDesktop ? tw`hidden` : tw``)};
  }
`;

export const SmTitleLeadership = styled.h3`
  ${tw`font-bold font-serif mb-48 md:mb-64 text-lg uppercase`};
  ${props => (props.dark ? tw`text-purple-200` : tw`text-grey`)}
  ${props => (props.hideMobile ? tw`hidden` : tw``)};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0 md:mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6 md:mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8 md:mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12 md:mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14 md:mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16 md:mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24 md:mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32 md:mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48 md:mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64 md:mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144 md:mb-144`)}
  line-height: ${math('24/19')};
  text-align: center;
  
  @media (min-width: ${theme.screens.md}) {
    ${tw`block`};
    ${props => (props.hideDesktop ? tw`hidden` : tw``)};
  }
`;

export const XsTitle = styled.h4`
  ${tw`font-bold font-serif mb-32 pt-6 text-sm text-purple-200 uppercase`};
  ${props =>
    (props.marginBottom === '0' && tw`mb-0`) ||
    (props.marginBottom === '6' && tw`mb-6`) ||
    (props.marginBottom === '8' && tw`mb-8`) ||
    (props.marginBottom === '12' && tw`mb-12`) ||
    (props.marginBottom === '14' && tw`mb-14`) ||
    (props.marginBottom === '16' && tw`mb-16`) ||
    (props.marginBottom === '24' && tw`mb-24`) ||
    (props.marginBottom === '32' && tw`mb-32`) ||
    (props.marginBottom === '48' && tw`mb-48`) ||
    (props.marginBottom === '64' && tw`mb-64`) ||
    (props.marginBottom === '144' && tw`mb-144`)}
  line-height: 1;
`;
